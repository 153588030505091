import { DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map } from 'rxjs';

import { useLocalState } from '@cosmos/state';
import { ResizeObserverDirective } from '@cosmos/ui-resize-observer-directive';

import { CosGlobalHeaderLocalState } from './global-header.local-state';

@Component({
  selector: 'cos-global-header',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './global-header.component.html',
  styleUrl: './global-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ResizeObserverDirective],
  host: {
    class: 'cos-global-header',
  },
})
export class CosGlobalHeaderComponent {
  private readonly _document = inject(DOCUMENT);
  private readonly _resizeDirective = inject(ResizeObserverDirective, {
    host: true,
  });

  readonly state = useLocalState(CosGlobalHeaderLocalState, this);

  constructor() {
    outputToObservable(this._resizeDirective.cosOnResize)
      .pipe(
        map((entry) => entry.contentRect.height),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe((height) => {
        this._document.documentElement.style.setProperty(
          `--header-height`,
          `${height}px`
        );
      });
  }

  toggleSecondaryOpen() {
    this.state.toggleSecondaryOpen();
  }
}
